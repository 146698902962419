<template>
  <div class="address">
    <!-- <title-top>我的地址</title-top> -->
    <div class="row align-center bg-white h96 mb16 w100 position_f" style="top:0; max-width:750px;">
      <div class="row flex-center" style="width:1.173333rem;" @click="onBack">
        <img class="imgsize44" src="~images/fanhui.png" alt />
      </div>
      <span class="flex1 row flex-center font36 f-1A1A1A">我的地址</span>
      <div class="row flex-center" style="width:1.333333rem;">
         <img class="imgsize88" src="~images/delicon.png" alt  v-if="!isShow"  @click="isShow=!isShow"  /> 
        <span class="font24 f-999999 mr25" v-else @click="isShow=!isShow">完成</span>
      </div>
    </div>
    <div class="h96"></div>
    <div v-for="(item,index) in list" :key="index">
      <div class="column mb8" v-if="index==0">
        <div class="row flex-start bg-white">
          <img class="w100 bg-white" src="~images/orderban.png" alt />
        </div>
        <div class="row align-center bg-white h210" @click="onItem(item)">
          <div class="column font24 f-333333 ml25">
            <div class="row mb20">
              <span>{{item.contact_name}}</span>
              <span class="ml40">{{item.mobile}}</span>
            </div>
            <div class="row align-center">
              <img class="imgsize32" src="~images/dingwei.png" alt />
              <span class="f-999999">{{item.address}}</span>
            </div>
          </div>
        </div>
        <div class="row between font24 bg-white" v-if="isShow">
          <div class="row align-center ml25 f-F62341" @click="onDef(item.id)">
            <img class="imgsize32 mr16" src="~images/selecticon.png" alt />
            <span>设为默认</span>
          </div>
          <span class="row flex-center mr25 f-white br4 bg-F7405A mb8 delsize" @click="onDel(item.id)">删除</span>
        </div>
        <div class="row flex-start bg-white">
          <img class="w100 bg-white" src="~images/orderban.png" alt />
        </div>
      </div>
      <div class="column bg-white mb8" v-else>
        <div class="row align-center bg-white h210"  @click="onItem(item)">
          <div class="column font24 f-333333 ml25 w100">
            <div class="row mb20">
              <span>{{item.contact_name}}</span>
              <span class="ml40">{{item.mobile}}</span>
            </div>
            <div class="row align-center">
              <img class="imgsize32" src="~images/dingwei.png" alt />
              <span class="f-999999">{{item.address}}</span>
            </div>
          </div>
        </div>
        <div class="row between font24 bg-white" v-if="isShow">
          <div class="row align-center ml25 f-333333" @click="onDef(item.id)">
            <img class="imgsize32 mr16" src="~images/not-sel.png" alt />
            <span>设为默认</span>
          </div>
          <span class="row flex-center mr25 f-white br4 bg-F7405A mb8 delsize" @click="onDel(item.id)">删除</span>
        </div>
      </div>
    </div>
    <div class="h146"></div>
    <div class="row flex-center bg-white" @click="onSure">
      <div
        class="btn-red position_f"
        style="background-color:#41ABA7;width:8rem;bottom:.426667rem;"
      >添加地址</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { addressList, delAddr,set_as_default } from "network/igou2";
export default {
  name: "Address",
  data() {
    return {
      list: [],
      isShow:false
    };
  },
  mounted() {
    this._addressList();
  },
  methods: {
    onItem(item) {
      this._set_as_default(item.id).then(res=>{
        this.$router.back();
      })
    },
    onDef(id){
      this._set_as_default(id).then(res=>{
        this._addressList();
      })
    },
    onDel(id){
      this._delAddr(id);
    },
    _set_as_default(id){
      return set_as_default(id)
    },
    _delAddr(id) {
      delAddr(id).then(res => {
        console.log(res);
        this._addressList();
      });
    },
    onSure() {
      this.$router.push("/zyaddaddr");
    },
    onBack() {
      this.$router.back();
    },
    _addressList() {
      addressList().then(res => {
        console.log(res);
        this.list = res.data;
      });
    }
  },

  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.delsize {
  width: 1.066667rem;
  height: 0.666667rem;
}
</style>
